import {
  Card,
  CardContent,
  OutlinedInput,
  IconButton,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  TablePagination,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import config from "../../utils/config";
import { toast } from "react-toastify";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";

const numRows = 10;

function Students(props) {
  const [students, setStudents] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuStudent, setMenuStudent] = useState({});
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationOnYes, setConfirmationOnYes] = useState(null);
  const [confirmationOnClose, setConfirmationOnClose] = useState(null);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [onlyValid, setOnlyValid] = useState(true);

  useEffect(() => {
    props.setTitle("Students");
  }, []); // eslint-disable-line

  useEffect(() => {
    setPage(0);
    setLastPage(0);
    fetchStudents(0);
  }, [props.organization]); // eslint-disable-line

  useEffect(() => {
    onSearchParamsChanged();
  }, [searchText, onlyValid]); // eslint-disable-line

  const onSearchParamsChanged = useDebouncedCallback(() => {
    setPage(0);
    setLastPage(0);
    fetchStudents(0);
  }, 500);

  const exportStudents = useDebouncedCallback(
    () => {
      axios
        .post(
          `${config.API_ENDPOINT}/students/${props.organization}/export`,
          {},
          { headers: { "x-access-token": localStorage.getItem("access-token") } }
        )
        .then(() => toast.success("You will receive an email with your file soon"))
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const handlePageChange = (_, newPage) => {
    if (newPage > lastPage) {
      fetchStudents(newPage);
      setLastPage(newPage);
    }

    setPage(newPage);
  };

  const fetchNumStudents = () => {
    let q = `${config.API_ENDPOINT}/students/${props.organization}/count`;

    if (searchText !== "") {
      q += `?search=${searchText}`;
    }

    axios
      .get(q, { headers: { "x-access-token": localStorage.getItem("access-token") } })
      .then((res) => setTotalRows(res.data.count))
      .catch(() => {
        toast.error("An unexpected error has occurred");
      });
  };

  const fetchStudents = useDebouncedCallback((page) => {
    fetchNumStudents();

    let q = `${config.API_ENDPOINT}/students/${props.organization}/all?page=${page}&numRows=${numRows}&valid=${onlyValid}`;

    if (searchText !== "") {
      q += `&search=${searchText}`;
    }

    axios
      .get(q, { headers: { "x-access-token": localStorage.getItem("access-token") } })
      .then((res) => {
        if (page === 0) {
          setStudents(res.data);
        } else {
          setStudents([...students, ...res.data]);
        }
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  }, 500);

  const deleteStudent = useDebouncedCallback(
    (studentId) => {
      axios
        .delete(`${config.API_ENDPOINT}/students/${props.organization}/${studentId}`, {
          headers: { "x-access-token": localStorage.getItem("access-token") },
        })
        .then((res) => {
          toast.success("Success!");
          setConfirmationOpen(false);
          setMenuAnchorEl(null);
          setStudents([]);
          setPage(0);
          setLastPage(0);
          fetchStudents(0);
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            case 404:
              toast.warn("You don't have enough permissions to delete this student");
              setConfirmationOpen(false);
              setMenuAnchorEl(null);
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const showDeleteDialog = (studentId) => {
    setConfirmationMessage("Are you sure you want to delete this student?");
    setConfirmationOnClose(() => () => {
      setConfirmationOpen(false);
      setMenuAnchorEl(null);
    });
    setConfirmationOnYes(() => () => {
      setConfirmationOpen(false);
      setMenuAnchorEl(null);
      deleteStudent(studentId);
    });
    setConfirmationOpen(true);
  };

  return (
    <div>
      <Card style={{ padding: 15 }}>
        <Button onClick={exportStudents} style={{ marginBottom: 5, float: "right" }}>
          Export Valid Students
        </Button>
        <CardContent>
          <OutlinedInput
            required
            fullWidth
            name="search"
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search by Name/Passport/ESNcard"
            endAdornment={
              <InputAdornment position="end">
                {searchText === "" ? null : (
                  <IconButton onClick={() => setSearchText("")}>
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
          <FormControlLabel
            sx={{ mb: 5 }}
            value="start"
            control={
              <Checkbox
                color="primary"
                checked={onlyValid}
                onChange={(e) => {
                  setOnlyValid(e.target.checked);
                }}
              />
            }
            label="Include only valid"
            labelPlacement="start"
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Nationality</TableCell>
                  <TableCell>ID/Passport</TableCell>
                  <TableCell>University</TableCell>
                  <TableCell>ESNcard</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Settings</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.slice(page * numRows, page * numRows + numRows).map((student) => (
                  <TableRow key={student._id}>
                    <TableCell>{student.name}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>{student.country}</TableCell>
                    <TableCell>{student.passport}</TableCell>
                    <TableCell>{student.university}</TableCell>
                    <TableCell>{student.esnCard}</TableCell>
                    <TableCell>
                      {moment(student.registerDate).add(1, "year").isAfter(moment())
                        ? "Valid"
                        : "Expired"}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(ev) => {
                          setMenuAnchorEl(ev.currentTarget);
                          setMenuStudent(student);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[numRows]}
              rowsPerPage={numRows}
              component="div"
              count={totalRows}
              page={page}
              onPageChange={handlePageChange}
            />
          </TableContainer>
          <Menu
            anchorEl={menuAnchorEl}
            open={menuAnchorEl != null}
            keepMounted
            onClose={() => setMenuAnchorEl(null)}
          >
            <Link
              to={`/student-profile/${menuStudent._id}`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <MenuItem>View Profile</MenuItem>
            </Link>
            <Link
              to={`/edit-student/${menuStudent._id}`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <MenuItem>Edit</MenuItem>
            </Link>
            <Link
              to={{
                pathname: "/send-email",
                state: {
                  emails: [menuStudent.email],
                },
              }}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <MenuItem>Send Email</MenuItem>
            </Link>
            <MenuItem
              onClick={() => {
                showDeleteDialog(menuStudent._id);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </CardContent>
      </Card>
      <ConfirmationDialog
        open={confirmationOpen}
        message={confirmationMessage}
        onYes={confirmationOnYes}
        onClose={confirmationOnClose}
      />
    </div>
  );
}

export default Students;
